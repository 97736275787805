// Polyfills
import "whatwg-fetch"

import config from "./config.json"

import { SentryContext, initSentry } from "@heyhabito/sentry"

const sentryContext = initSentry({
  dsn: config.sentryDsn,
  isProd: config.sentryDsn !== "",
})

/**
 * Wrap the root element of the Gatsby app in our Sentry provider, to ensure
 * Sentry is always available no matter where it's called from..
 *
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
 */
export const wrapRootElement = ({ element }) => {
  return (
    <SentryContext.Provider value={sentryContext}>
      {element}
    </SentryContext.Provider>
  )
}
