exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-auth-error-tsx": () => import("./../../../src/pages/auth-error.tsx" /* webpackChunkName: "component---src-pages-auth-error-tsx" */),
  "component---src-pages-buy-to-let-mortgage-calculator-tsx": () => import("./../../../src/pages/buy-to-let-mortgage-calculator.tsx" /* webpackChunkName: "component---src-pages-buy-to-let-mortgage-calculator-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-culture-how-we-work-tsx": () => import("./../../../src/pages/culture/how-we-work.tsx" /* webpackChunkName: "component---src-pages-culture-how-we-work-tsx" */),
  "component---src-pages-culture-index-tsx": () => import("./../../../src/pages/culture/index.tsx" /* webpackChunkName: "component---src-pages-culture-index-tsx" */),
  "component---src-pages-culture-mission-tsx": () => import("./../../../src/pages/culture/mission.tsx" /* webpackChunkName: "component---src-pages-culture-mission-tsx" */),
  "component---src-pages-culture-values-tsx": () => import("./../../../src/pages/culture/values.tsx" /* webpackChunkName: "component---src-pages-culture-values-tsx" */),
  "component---src-pages-culture-you-and-habito-tsx": () => import("./../../../src/pages/culture/you-and-habito.tsx" /* webpackChunkName: "component---src-pages-culture-you-and-habito-tsx" */),
  "component---src-pages-embeddable-mortgage-comparison-tsx": () => import("./../../../src/pages/embeddable-mortgage-comparison.tsx" /* webpackChunkName: "component---src-pages-embeddable-mortgage-comparison-tsx" */),
  "component---src-pages-expired-magic-link-tsx": () => import("./../../../src/pages/expired-magic-link.tsx" /* webpackChunkName: "component---src-pages-expired-magic-link-tsx" */),
  "component---src-pages-home-buying-tsx": () => import("./../../../src/pages/home-buying.tsx" /* webpackChunkName: "component---src-pages-home-buying-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kamasutra-tsx": () => import("./../../../src/pages/kamasutra.tsx" /* webpackChunkName: "component---src-pages-kamasutra-tsx" */),
  "component---src-pages-log-in-tsx": () => import("./../../../src/pages/log-in.tsx" /* webpackChunkName: "component---src-pages-log-in-tsx" */),
  "component---src-pages-missing-email-tsx": () => import("./../../../src/pages/missing-email.tsx" /* webpackChunkName: "component---src-pages-missing-email-tsx" */),
  "component---src-pages-more-than-a-broker-tsx": () => import("./../../../src/pages/more-than-a-broker.tsx" /* webpackChunkName: "component---src-pages-more-than-a-broker-tsx" */),
  "component---src-pages-mortgage-comparison-tsx": () => import("./../../../src/pages/mortgage-comparison.tsx" /* webpackChunkName: "component---src-pages-mortgage-comparison-tsx" */),
  "component---src-pages-mortgage-in-principle-tsx": () => import("./../../../src/pages/mortgage-in-principle.tsx" /* webpackChunkName: "component---src-pages-mortgage-in-principle-tsx" */),
  "component---src-pages-mortgage-repayment-calculator-tsx": () => import("./../../../src/pages/mortgage-repayment-calculator.tsx" /* webpackChunkName: "component---src-pages-mortgage-repayment-calculator-tsx" */),
  "component---src-pages-partner-money-new-mortgage-index-tsx": () => import("./../../../src/pages/partner/money/new-mortgage/index.tsx" /* webpackChunkName: "component---src-pages-partner-money-new-mortgage-index-tsx" */),
  "component---src-pages-partner-money-remortgage-index-tsx": () => import("./../../../src/pages/partner/money/remortgage/index.tsx" /* webpackChunkName: "component---src-pages-partner-money-remortgage-index-tsx" */),
  "component---src-pages-permissions-error-tsx": () => import("./../../../src/pages/permissions-error.tsx" /* webpackChunkName: "component---src-pages-permissions-error-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-protection-get-tsx": () => import("./../../../src/pages/protection-get.tsx" /* webpackChunkName: "component---src-pages-protection-get-tsx" */),
  "component---src-pages-protection-importance-tsx": () => import("./../../../src/pages/protection-importance.tsx" /* webpackChunkName: "component---src-pages-protection-importance-tsx" */),
  "component---src-pages-protection-types-tsx": () => import("./../../../src/pages/protection-types.tsx" /* webpackChunkName: "component---src-pages-protection-types-tsx" */),
  "component---src-pages-set-token-tsx": () => import("./../../../src/pages/set-token.tsx" /* webpackChunkName: "component---src-pages-set-token-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-out-tsx": () => import("./../../../src/pages/sign-out.tsx" /* webpackChunkName: "component---src-pages-sign-out-tsx" */),
  "component---src-pages-sign-up-buy-to-let-tsx": () => import("./../../../src/pages/sign-up/buy-to-let.tsx" /* webpackChunkName: "component---src-pages-sign-up-buy-to-let-tsx" */),
  "component---src-pages-sign-up-index-tsx": () => import("./../../../src/pages/sign-up/index.tsx" /* webpackChunkName: "component---src-pages-sign-up-index-tsx" */),
  "component---src-pages-sign-up-lets-get-you-started-tsx": () => import("./../../../src/pages/sign-up/lets-get-you-started.tsx" /* webpackChunkName: "component---src-pages-sign-up-lets-get-you-started-tsx" */),
  "component---src-pages-sign-up-mortgage-in-principle-tsx": () => import("./../../../src/pages/sign-up/mortgage-in-principle.tsx" /* webpackChunkName: "component---src-pages-sign-up-mortgage-in-principle-tsx" */),
  "component---src-pages-sign-up-purchase-tsx": () => import("./../../../src/pages/sign-up/purchase.tsx" /* webpackChunkName: "component---src-pages-sign-up-purchase-tsx" */),
  "component---src-pages-sign-up-remortgage-tsx": () => import("./../../../src/pages/sign-up/remortgage.tsx" /* webpackChunkName: "component---src-pages-sign-up-remortgage-tsx" */),
  "component---src-pages-sign-up-speak-to-an-expert-tsx": () => import("./../../../src/pages/sign-up/speak-to-an-expert.tsx" /* webpackChunkName: "component---src-pages-sign-up-speak-to-an-expert-tsx" */),
  "component---src-pages-stamp-duty-calculator-tsx": () => import("./../../../src/pages/stamp-duty-calculator.tsx" /* webpackChunkName: "component---src-pages-stamp-duty-calculator-tsx" */),
  "component---src-pages-unset-token-tsx": () => import("./../../../src/pages/unset-token.tsx" /* webpackChunkName: "component---src-pages-unset-token-tsx" */),
  "component---src-pages-verify-tsx": () => import("./../../../src/pages/verify.tsx" /* webpackChunkName: "component---src-pages-verify-tsx" */),
  "component---src-templates-mortgage-calculator-tsx": () => import("./../../../src/templates/MortgageCalculator.tsx" /* webpackChunkName: "component---src-templates-mortgage-calculator-tsx" */),
  "component---src-templates-mortgage-comparison-ltv-tsx": () => import("./../../../src/templates/MortgageComparisonLTV.tsx" /* webpackChunkName: "component---src-templates-mortgage-comparison-ltv-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/Partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */)
}

