module.exports = [{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-17a3858976/0/cache/gatsby-plugin-manifest-npm-4.25.0-25ef1b75df-74b960a176.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Habito","short_name":"Habito","start_url":"/","background_color":"#ffffff","theme_color":"#151515","display":"minimal-ui","icon":"src/assets/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aa37929e7175a4fd3e257ad628049862"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../.yarn/unplugged/gatsby-virtual-d41c096774/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
